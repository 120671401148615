<template>
    <div class="error-container">
      <div class="error-card">
        <h3>Something Went Wrong!</h3>
        <p>Please check your configurations and try again.</p>
        <p>If you don't have access, please contact your administrator.</p>
        <!-- <button @click="reloadPage">Retry</button> -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ErrorComponent',
    methods: {
    //   reloadPage() {
    //     window.location.reload();
    //   }
    }
  }
  </script>
  
  <style scoped>
  .error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; 
    background: #f8f9fa; */
  }
  
  .error-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px 40px;
    text-align: center;
    /*max-width: 400px; */
    width: 90%;
    margin:1.5em;
  }
  
  h3 {
    color: #e74c3c;
    margin-bottom: 15px;
    font-size: 24px;
  }
  
  p {
    color: #555;
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  button {
    margin-top: 20px;
    background: #3498db;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  button:hover {
    background: #2980b9;
  }
  </style>
  